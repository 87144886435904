<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceTest-container common-padding">
    <!-- <div style="margin: 16px 0 0 16px">
      今日新增: {{ sumNum.todayNum || "0" }}, 本月新增: {{ sumNum.monthNum || "0" }}
    </div> -->

    <div class="common-Summary-container no-after">
      <div class="Summary-item">
        <div class="item-title">昨日新增质检次数</div>
         
        <div @click="() => { collect(
                'yesterdayNum',
                '昨日新增质检次数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '昨日质检次数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ sumNum.yesterdayNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">今日新增质检次数</div>
         
        <div @click="() => { collect(
                'todayNum',
                '今日新增质检次数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '今日质检次数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ sumNum.todayNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">本周新增质检次数</div>
         
        <div @click="() => { collect(
                'weekNum',
                '本周新增质检次数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '本周质检次数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ sumNum.weekNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">本月新增质检次数</div>
         
        <div @click="() => { collect(
                'monthNum',
                '本月新增质检次数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '本月质检次数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ sumNum.monthNum || "0" }}</div>
      </div>
    </div>
    <!-- <div v-if="isMore" v-loading="loading2" element-loading-text="数据较多，拼命加载中...">
      <div class="common-Summary-container no-after">
        <div class="Summary-item">
          <div class="item-title">服务店铺数</div>
          <div @click="() => { collect(
                'shops',
                '服务店铺数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '累计质检次数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ headerData.shops || "0" }}</div>
        </div>
        <div class="Summary-item">
          <div class="item-title">质检店铺数</div>
          <div @click="() => { collect(
                'qualityedShops',
                '质检店铺数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '累计质检次数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ headerData.qualityedShops || "0" }}</div>
        </div>
        <div class="Summary-item">
          <div class="item-title">应质检店铺数</div>
          <div @click="() => { collect(
                'shouldQualityShops',
                '应质检店铺数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '累计质检次数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ headerData.shouldQualityShops || "0" }}</div>
        </div>
        <div class="Summary-item">
          <div class="item-title">实际质检店铺数</div>
          <div @click="() => { collect(
                'qualityedShops',
                '质检店铺数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '累计质检次数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ headerData.qualityedShops || "0" }}</div>
        </div>
        <div class="Summary-item">
          <div class="item-title">未质检店铺数</div>
          <div @click="() => { collect(
                'unQualityShops',
                '未质检店铺数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '累计质检次数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ headerData.unQualityShops || "0" }}</div>
        </div>
      </div>
      <div class="common-Summary-container">
        <div class="Summary-item">
          <div class="item-title">质检店铺率</div>
          <div class="item-num common-nofinger">{{ headerData.shopRate || "0" }}%</div>
        </div>
        <div class="Summary-item">
          <div class="item-title">应质检条数</div>
           
          <div @click="() => { collect(
                'shouldQualitys',
                '应质检条数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '应质检条数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ headerData.shouldQualitys || "0" }}</div>
        </div>
        <div class="Summary-item">
          <div class="item-title">实际质检条数</div>
           
          <div @click="() => { collect(
                'qualitys',
                '实际质检条数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '实际质检条数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ headerData.qualitys || "0" }}</div>
        </div>
        <div class="Summary-item">
          <div class="item-title">实际质检比例</div>
          <div class="item-num common-nofinger">{{ headerData.qualitysRate || "0" }}%</div>
        </div>
        <div class="Summary-item">
          <div class="item-title">质检已读比例</div>
          <div class="item-num common-nofinger">{{ headerData.readsRate || "0" }}%</div>
        </div>
        <div class="Summary-item">
          <div class="item-title">质检已读条数</div>
           
          <div @click="() => { collect(
                'reads',
                '质检已读条数',
                [
                  { name: '店铺名称', type: 'input', modelKey: 'shopName' },
                  
                  { name: '所属客户', type: 'customerScreen', modelKey: 'customerId', selOpt: rolesCompany },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属公司', colProp: ['cpmpanyName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '质检已读条数', colProp: ['qualityCount'] },
                ]
              )}" class="item-num">{{ headerData.reads || "0" }}</div>
        </div>
      </div>
    </div>
     <div class="common-isMore-btn" @click="()=> {showOpen()}">{{isMore ? '收起' : '查看更多'}}<i v-if="!isMore" class="el-icon-caret-bottom"></i><i v-if="isMore" class="el-icon-caret-top"></i></div> -->

    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>店铺名称:</span>
        <el-input
          class="common-screen-input"
          v-model="params.keyword"
          placeholder="请输入"
        ></el-input>
      </div>
      <!-- <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          @change="commonFun(true)"
          v-model="params.companyId"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div> -->
      <!-- <div class="common-input-container">
        <span>所属平台/所属类目:</span>
        <el-cascader
          :options="platList"
          class="common-screen-input"
          :props="{ checkStrictly: true, label: 'platformName', value: 'id' }"
          clearable
          v-model="platformId"
          @change="commonFun"
        >
        </el-cascader>
      </div> -->
      <!-- <div class="common-input-container">
        <span>所属平台/所属类目:</span>
        <a-cascader
          class="common-screen-input"
          v-model="platformId"
          :options="platList"
          :fieldNames="{
            label: 'platformName',
            value: 'id',
            children: 'children'
          }"
          placeholder="请选择"
          change-on-select
          @change="commonFun"
        />
      </div> -->
      <div class="common-input-container">
          <span>所属平台:</span>
          <el-select class="common-screen-input" v-model="params.platId" placeholder="所属平台" @change="commonFun" clearable filterable >
          <el-option v-for="item in platList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
          </el-select>
      </div>
      <div class="common-input-container">
          <span>所属类目:</span>
          <el-select class="common-screen-input" v-model="params.categoryId" placeholder="所属类目" @change="commonFun" clearable filterable remote :remote-method="(val)=>{remoteCategory(val)}" @focus="selectClear">
          <el-option v-for="item in categoryList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
          <div class="common-select-page">
              <div v-if="categoryParams.pageNum != 1" @click="() => {selectPage('up')}">上一页</div>
              <div v-if="categoryParams.pageNum == 1" style="color: #999">上一页</div>
              <div v-if="categoryList && categoryList.length == 10" @click="() => {selectPage('down')}">下一页</div>
              <div v-if="categoryList && categoryList.length < 10" style="color: #999">下一页</div>
          </div>
          </el-select>
      </div>
      <br />
      <div class="common-input-container">
        <span>所属客户:</span>
        <el-select
          class="common-screen-input"
          v-model="params.customerId"
          placeholder="请搜索客户"
          @change="commonFun"
          clearable
          filterable
          remote
          :remote-method="
            (val) => {
              remoteMethod(val);
            }
          "
        >
          <el-option
            v-for="item in finishedList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>客服账号:</span>
        <el-select class="common-screen-input" v-model="params.adminId" placeholder="所属客服" @change="commonFun" clearable filterable >
          <el-option v-for="item in selectService" :key="item.id" :label="item.nickName" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>选择日期:</span>
        <el-date-picker
          v-model="params.checkDate"
          type="date"
          placeholder="请选择日期"
          value-format="yyyy-MM-dd"
          class="common-screen-input"
          @change="commonFun"
        >
        </el-date-picker>
      </div>
      <el-button class="common-screen-btn" type="primary" @click="commonFun"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" @click="() => resetBtn()" plain
        >重 置</el-button
      >
    </div>
    <!-- <div style="margin: 16px 0 0 16px">
      今日新增: {{ sumNum.todayNum || "0" }}, 本月新增: {{ sumNum.monthNum || "0" }}
    </div> -->
    <el-table class="common-table" :data="tableData">
      <el-table-column
        prop="shopName"
        label="店铺名称"
        width="190px"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="cpmpanyName"
        label="所属公司"
        width="180px"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="platformName"
        label="所属平台"
        width="120px"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="categoryName"
        label="所属类目"
        width="120px"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="customerName"
        label="所属客户"
        width="190px"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="adminNum"
        label="客服人数"
        width="110px"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="subaccountNum"
        label="子账号数量"
        width="110px"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="qualityNum"
        label="累计质检次数"
        width="110px"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="lastTime"
        label="最近质检时间"
        :formatter="tableColumn"
        width="190px"
      ></el-table-column>
      <el-table-column
        prop="chatNumber"
        label="聊天数量"
        :formatter="tableColumn"
        width="100px"
      ></el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                $router.push({
                  path: '/service/service-test/detail-test',
                  query: {
                    shopid: scope.row.shopId,
                    shopname: scope.row.shopName, //店名
                    companyname: scope.row.cpmpanyName, //公司
                    platformname: scope.row.platformName, //类目、平台
                    customername: scope.row.customerName //客户
                  }
                });
              }
            "
            >质检评审</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="
              () => {
                $router.push({
                  path: '/service/service-test/test-record',
                  query: {
                    shopid: scope.row.shopId,
                    shopname: scope.row.shopName, //店名
                    companyname: scope.row.cpmpanyName, //公司
                    platformname: scope.row.platformName, //类目、平台
                    customername: scope.row.customerName //客户
                  }
                });
              }
            "
            >质检记录</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
     <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="serTestSum"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { serviceTestList, qualityRecord, serTestSum } from "../../service/service.js";
import { getPlatList, getCustList, selectCategory, selectPlatform, getRoleByType } from "../../service/common.js";
import { tableColumn, Config } from "../../utils/index.js";
// import { Cascader } from "ant-design-vue";
import CommonSumDialog from "../../components/common/commonSumDialog"
import { mapState } from "vuex";

export default {
  components: {
    Breadcrumb,
    // aCascader: Cascader,
    CommonSumDialog
  },
  data() {
    return {
      isMore: false, // 查看更多
      tableColumn,
      loading: false,
      loading2: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "客服", isLink: false },
        { title: "客服质检", isLink: false }
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        page: 1,
        pageSize: 10
      },
      platformId: [],
      finishedList: [], // 所属客户下拉
      platList: [], // 所属平台
      categoryList: [], //类目下拉
      categoryParams: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      roleSelect: "",
      selectService: [], // 客服下拉
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      tableData: [],
      sumNum: {}, // 汇总
      headerData: {},
      // 汇总弹窗所需数据
      commonSumVisible: false,
      serTestSum, // 列表接口方法
    };
  },
  created() {
    // 获取comID
    this.getComId()

    this.getRoleByType(4);
    this.selectPlatform();
    this.selectCategory();
    // this.serviceTestList();
    this.getCustList();
    // this.getQualityRecord();
  },
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.serviceTestList();
        // this.getQualityRecord();
      },400)
    },
    async getRoleByType(id) {
      let { data } = await getRoleByType({ companyId: this.comId }, id);
      this.selectService = data;
    },
    selectClear () { // 下拉清空时触发
        this.categoryParams.pageNum = 1
        this.categoryParams.name = ''
        this.selectCategory()
    },
    selectPage(type) { // 下拉分页
        if (type == 'up') {
            this.categoryParams.pageNum--
        } else {
            this.categoryParams.pageNum++
        }
        this.selectCategory()
    },
    remoteCategory(val) { // 所属类目下拉-筛选
        this.categoryParams.pageNum = 1
        this.categoryParams.name = val
        this.selectCategory()
    },
    async selectCategory() { // 类目下拉
        let categoryParams = {...this.categoryParams}
        let { data } = await selectCategory(categoryParams)
        this.categoryList = data.list
    },
    async selectPlatform() { // 平台下拉
        let { data } = await selectPlatform({pageNum: -1})
        this.platList = data
    },
    // 数据汇总弹窗-start
    sumVisibleClose () {
      this.commonSumVisible = false
    },
    collect(type, name, screen, arr) { // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(type, name, screen, arr, this.params.companyId);
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗 -end

    remoteMethod(val) {
      this.getCustList(val);
    },
    async getCustList(name) {
      // 所属客户下拉
      let resData = (await getCustList({ customerName: name, companyId: this.comId })).data;
      this.finishedList = resData;
    },
    resetBtn() {
      this.params = {
        page: 1,
        pageSize: 10,
        companyId: this.comId
      };
      this.platformId = [];
      this.finishedList = [];
      this.serviceTestList();
      // this.getQualityRecord();
    },
    // commonFun(flag) {
    commonFun() {
      this.params.page = 1;
      this.params.pageSize = 10;
      this.serviceTestList();
      // if (flag) {
        // this.getQualityRecord();
      // }
    },
    async getPlatList() {
      // 所属平台/类目下拉
      let resData = (await getPlatList({ pageNum: -1 })).data;
      this.platList = resData;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.serviceTestList();
    },
    async serviceTestList() {
      // 客服质检列表
      this.loading = true;
      // let platformId = [...this.platformId];
      let params = { ...this.params };
      // if (platformId && platformId.length) {
      //   params.platId = platformId[0];
      //   if (platformId[1]) {
      //     params.categoryId = platformId[1];
      //   }
      // }
      // else {
      //   delete platformId;
      // }
      let resData = (await serviceTestList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.sumNum = {
        yesterdayNum: resData.yesterdayNum,
        weekNum: resData.weekNum,
        todayNum: resData.todayNum,
        monthNum: resData.monthNum
      };
      this.loading = false;
    },
    async getQualityRecord() {
      this.loading2 = true;
      let id = this.params.companyId;
      const { data } = await qualityRecord({ companyId: id });
      this.headerData = data;
      this.loading2 = false;
    },
    showOpen(){
      this.isMore = !this.isMore
      this.params.companyId = this.comId
      if(this.isMore){
        // this.getQualityRecord()
      }
    }
  }
};
</script>
<style lang="less" scoped>
.serviceTest-container {
  text-align: left;
  .isMore-height {
    transition: height 0.5s;
    overflow: hidden;
    height: 220px;
  }
  .isMore-height0 {
    transition: height 0.5s;
    overflow: hidden;
    height: 0;
    padding: 0;
  }
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
/deep/.el-table .cell {
  white-space: nowrap;
}
.common-Summary-container {
  &:nth-child(-n + 2) {
    padding: 0;
    &::after {
      width: 0;
      height: 0;
    }
  }
  &:nth-child(3) {
    padding-top: 0;
  }
}
</style>
